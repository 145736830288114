import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import HeroVideo from '../components/hero-video'
import TeamCard from '../components/team-card'
import Layout from '../layouts/layout'

interface TeamCardsContentful {
    title: String,
    description: { description: String },
    media: { url: String }
}

const MeetTheTeamPage = () => {

    const data = useStaticQuery(graphql`
        query TeamQuery {
            videoData: contentfulLayout(contentful_id: {eq: "T3H8XFGXUuivr4KGfj91v"}) {
                hero {
                    header_title
                    hero_body {
                      hero_body
                    }
                    heroImage {
                      url
                    }
                }
            }
            contentData: contentfulLayout(contentful_id: {eq: "37IRF7UrEPqWhSRddGAM5g"}) {
                contentModule {
                    ... on ContentfulContentObject {
                        title
                        description {
                            description
                        }
                        media {
                            url
                        }
                    }
                }
                hero {
                    header_title
                }
            }
        }
    `)

    const page = data.contentData
    const videoPage = data.videoData

    const heroTitle = page.hero.header_title
    const heroImage = videoPage.hero.heroImage.url

    const team: TeamCardsContentful[] = page.contentModule.slice(1)

    return(
        <Layout>
            <Helmet>
                <title>Team | O3 Edge | Retirement Plan Prospecting for Advisors</title>
                <meta name="description" content="We're proud to have a team of experienced professionals to help you along the way. Meet the people behind O3 Edge." />
            </Helmet>

            <HeroVideo title={heroTitle} video={heroImage} />

            <div className="team-card-container bg-light">
                <Container>
                    <Row>
                        {team.map(card =>
                            <TeamCard name={card.title} position={card.description.description} image={card.media.url} />
                            )}
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export default MeetTheTeamPage